import React, { useState, useEffect, useContext } from "react";
import { Editor } from "primereact/editor";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../hook/authContext";

function AddOrEditPost() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const postToEdit = location.state?.post || null; // Extract post data if editing
  const isEditing = !!postToEdit; // Determine if we're editing
  const [imageOptions, setImageOptions] = useState(null); // Track image options visibility
  const [title, setTitle] = useState(postToEdit?.title || "");
  const [topic, setTopic] = useState(postToEdit?.topic || "");
  const [text, setText] = useState(postToEdit?.description || "");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [userId, setUserId] = useState("");
  const [postId, setPostId] = useState("");
  const [link, setLink] = useState(postToEdit?.link || "");
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const Id = isEditing ? postToEdit.user_id : user.id || '';
  useEffect(() => {
    if (isEditing && postToEdit) {
      setTitle(postToEdit.title || "");
      setTopic(postToEdit.topic || "");
      setText(postToEdit.description || "");
      setLink(postToEdit.link || "");
      setTags(postToEdit.tags.split(",") || []);
      setUserId(Id);
      setPostId(postToEdit.id);
      setImagePreview(
        "https://api.skipthegames.tech/post_images/" + postToEdit.image || ""
      );
      setImageFile(postToEdit.image);
    } else {
      setUserId(Id);
    }
  }, [isEditing, postToEdit]);

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "https://api.skipthegames.tech/description_images",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return response.status === 200 ? response.data.url : null;
    } catch (error) {
      setError("Error during image upload. Please try again.");
      console.error(
        "Error during image upload:",
        error.response?.data || error.message
      );
      return null;
    }
  };

  const handleImageClick = (event) => {
    if (event.target.tagName === "IMG") {
      const clickedImage = event.target;
      const rect = clickedImage.getBoundingClientRect(); // Get position of the clicked image
      setImageOptions({
        top: rect.top + window.scrollY + 10,
        left: rect.left + rect.width + 10,
        imageElement: clickedImage,
      });
      setSelectedImages([clickedImage]); // Mark the image as selected
    } else {
      setImageOptions(null); // Hide options when clicking elsewhere
      setSelectedImages([]);
    }
  };

  const resizeImage = (sizePercentage) => {
    if (imageOptions?.imageElement) {
      imageOptions.imageElement.style.width = `${sizePercentage}%`;
    }
  };

  const deleteImage = async () => {
    if (imageOptions?.imageElement) {
      const imageUrl = imageOptions.imageElement.getAttribute("src");
      try {
        const response = await axios.post("https://api.skipthegames.tech/delete", {
          url: imageUrl,
        });
        if (response.status === 200) {
          imageOptions.imageElement.remove(); // Remove the image from the editor
          setImageOptions(null); // Hide the options after deletion
        }
      } catch (error) {
        setError("Error during image deletion. Please try again.");
        console.error(
          "Error during image deletion:",
          error.response?.data || error.message
        );
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type.startsWith("image/") &&
      file.size <= 5 * 1024 * 1024
    ) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setError("Please upload a valid image (less than 5MB).");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim()) {
      e.preventDefault();
      const newTag = input.trim();
      if (!tags.includes(newTag)) {
        setTags((prevTags) => [...prevTags, newTag]);
      }
      setInput("");
    }
  };

  const removeTag = (index) =>
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));

  const isValidURL = (url) => /^(ftp|http|https):\/\/[^ "]+$/.test(url);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !topic || !text || !link) {
      setError("All fields are required!");
      return;
    }
    if (!isValidURL(link)) {
      setError("Please provide a valid URL.");
      return;
    }
    const tag = tags.join(" , ");
    const formData = new FormData();
    formData.append("title", title);
    formData.append("user_id", userId); // You might want to get the actual user ID
    formData.append("topic", topic);
    formData.append("description", text);
    formData.append("link", link);
    formData.append("tags", tag);
    if (imageFile) formData.append("image", imageFile);
    if (isEditing && postId) formData.append("id", postId);
    try {
      const url = isEditing
        ? "https://api.skipthegames.tech/update-post"
        : "https://api.skipthegames.tech/create-post";

      const response = await fetch(url, {
        method: isEditing ? "PUT" : "POST",
        body: formData,
      });

      if (response.ok) {
        setError(null);
        navigate("/dashboard/manage-post");
      } else {
        const data = await response.json();
        setError(data.message || "An error occurred.");
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    }
  };

  const renderHeader = () => <span className="ql-formats"></span>;
  const customModules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // Text formatting
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        [{ header: [1, 2, 3, false] }], // Headers
        ["link", "image"], // Links and images
      ],
      handlers: {
        image: function () {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/*";
          input.click();
          input.onchange = async () => {
            const file = input.files[0];
            if (file) {
              const imageUrl = await handleImageUpload(file);
              if (imageUrl) {
                const quill = this.quill;
                const range = quill.getSelection();
                quill.insertEmbed(range.index, "image", imageUrl);
              }
            }
          };
        },
      },
    },
  };

  return (
    <section id="add-post" className="section">
      <div className="add-post-header">
        <h4>{isEditing ? "Edit Your Post" : "Post Your Content"}</h4>
        {!isEditing && (
          <small>Your post will require moderator approval.</small>
        )}
      </div>
      <div className="container">
        <form
          id="postForm"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          {/* Title */}
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          {/* Topic */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="topic">Topic</label>
              <select
                id="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                required
              >
                <option value="">Select a topic</option>
                <option value="desi-new-videos">Desi new videos hd / sd</option>
                <option value="desi-old-videos">Desi Old Videos HD / SD</option>
                <option value="desi-new-pics">Desi new pics hd / sd</option>
                <option value="desi-old-pics">Desi Old Pictures HD / SD</option>
                <option value="desi-model-webcam-girls">
                  Desi model | Webcam Girls
                </option>
                <option value="live-streams-instragram-onlyfans">
                  Live Streams | Instragram | OnlyFans
                </option>
                <option value="world-pronster-videos">
                  World Pronster Videos
                </option>
              </select>
            </div>
            {/* Link */}
            <div className="form-group">
              <label htmlFor="link">Link</label>
              <input
                type="text"
                id="link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </div>
          </div>
          {/* Description */}
          <div className="form-group" id="ForF">
            <label htmlFor="description">Description</label>
            <div onClick={handleImageClick}>
              <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{ height: "420px" }}
                modules={customModules}
                headerTemplate={renderHeader()}
              />
              {imageOptions && (
                <div className="image-options">
                  <button onClick={() => resizeImage(50)}>50%</button>
                  <button onClick={() => resizeImage(75)}>75%</button>
                  <button onClick={() => resizeImage(100)}>100%</button>
                  <button onClick={deleteImage}>Delete</button>
                </div>
              )}
            </div>
          </div>
          {/* Thumbnail */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="image">Thumbnail</label>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                </div>
              )}
            </div>
            {/* Tags */}
            <div className="form-group">
              <label htmlFor="tags">Tags</label>
              <input
                type="text"
                id="tags"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type a tag and press Enter"
              />
              <div className="tag-container">
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="tag"
                    onClick={() => removeTag(index)}
                  >
                    {tag} &times;
                  </span>
                ))}
              </div>
            </div>
          </div>
          {/* Error */}
          {error && <div className="error">{error}</div>}
          {/* Submit Button */}
          <div className="form-group">
            <button type="submit" id="PostButton">
              {isEditing ? "Update Post" : "Publish Post"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AddOrEditPost;
