import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../hook/authContext";
import debounce from "lodash.debounce"; // Install lodash for debounce functionality

function VideoPosts() {
  const location = useLocation();
  const navigate = useNavigate();
  const postToEdit = location.state?.post || null;
  const isEditing = !!postToEdit;
  const { user } = useContext(AuthContext);

  // State variables
  const [title, setTitle] = useState(postToEdit?.title || "");
  const [slug, setSlug] = useState(postToEdit?.slug || "");
  const [isSlugUnique, setIsSlugUnique] = useState(false);
  const [isCheckingSlug, setIsCheckingSlug] = useState(false);
  const [slugError, setSlugError] = useState(null);

  const [topic, setTopic] = useState(postToEdit?.topic || "");
  const [imagePreview, setImagePreview] = useState(
    postToEdit?.image ? `https://api.skipthegames.tech/post_images/${postToEdit.image}` : null
  );
  const [imageFile, setImageFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(
    postToEdit?.video ? `https://api.skipthegames.tech/post_videos/${postToEdit.video}` : null
  );
  const [videoFile, setVideoFile] = useState(null);
  const [tags, setTags] = useState(postToEdit?.tags?.split(",") || []);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false); // Track if the post is uploading

  useEffect(() => {
    if (isEditing && postToEdit) {
      setTitle(postToEdit.title || "");
      setTopic(postToEdit.topic || "");
      setTags(postToEdit.tags.split(",") || []);
      setIsSlugUnique(true);
    }
  }, [isEditing, postToEdit]);

  // Function to generate slug from title
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  // Function to debounce the slug check
  const checkSlugUniqueness = debounce(async (slug) => {
    setIsCheckingSlug(true);
    try {
      const response = await fetch("https://api.skipthegames.tech/check-slug", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ slug }),
      });

      const result = await response.json();

      setIsSlugUnique(result.isUnique);
      setSlugError(result.isUnique ? null : "Slug already exists. Please choose another.");
    } catch (err) {
      console.error("Error checking slug uniqueness:", err);
      setSlugError("Error checking slug. Please try again.");
    } finally {
      setIsCheckingSlug(false);
    }
  }, 500);

  // Handle title change
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    const newSlug = generateSlug(newTitle);
    setSlug(newSlug);

    if (newTitle.split(" ").length >= 6) {
      checkSlugUniqueness(newSlug);
    }
  };

  const modifySlug = (slug) => {
    const slugWords = slug.split("-");
    if (slugWords.length > 8) {
      slug = slugWords.slice(0, 8).join("-");
    }
    if (!isSlugUnique) {
      slug = `${slug}-${Date.now()}`;
    }
    return slug;
  };

  const validateFile = (file, type) => {
    const maxSize = type === "image" ? 5 * 1024 * 1024 : 50 * 1024 * 1024;
    if (!file) return "No file selected.";
    if (type === "image" && !file.type.startsWith("image/")) return "Please upload a valid image.";
    if (type === "video" && !file.type.startsWith("video/")) return "Please upload a valid video.";
    if (file.size > maxSize) return `File size exceeds the ${maxSize / (1024 * 1024)}MB limit.`;
    return null;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const errorMessage = validateFile(file, "image");
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setImagePreview(reader.result);
    reader.readAsDataURL(file);
    setError(null);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    const errorMessage = validateFile(file, "video");
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setVideoFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setVideoPreview(reader.result);
    reader.readAsDataURL(file);
    setError(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim()) {
      e.preventDefault();
      const newTag = input.trim();
      if (!tags.includes(newTag)) {
        setTags((prevTags) => [...prevTags, newTag]);
      }
      setInput("");
    }
  };

  const removeTag = (index) => setTags((prevTags) => prevTags.filter((_, i) => i !== index));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSlugUnique) {
      setError("Please resolve the slug issue before submitting.");
      return;
    }

    if (!title || !topic || !imageFile || !videoFile) {
      setError("Please fill in all fields, including the image and video.");
      return;
    }

    setError(null);
    setLoading(true); // Start loading when submitting

    const finalSlug = modifySlug(slug);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("slug", finalSlug);
    formData.append("topic", topic);
    formData.append("user_id", user.id);
    formData.append("tags", tags.join(","));
    formData.append("image", imageFile);
    formData.append("video", videoFile);
    if (isEditing && postToEdit.id) formData.append("id", postToEdit.id);

    try {
      const url = isEditing
        ? "https://api.skipthegames.tech/update-video-post"
        : "https://api.skipthegames.tech/create-video-post";

      const xhr = new XMLHttpRequest();
      xhr.open(isEditing ? "PUT" : "POST", url, true);

      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          setUploadProgress(progress);
        }
      });

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          alert(isEditing ? "Post updated successfully!" : "Post created successfully!");
          navigate("/dashboard/manage-post");
        } else {
          const response = JSON.parse(xhr.responseText);
          setError(response.message || "An error occurred.");
        }
      };

      xhr.onerror = () => {
        setError("Something went wrong. Please try again.");
      };

      xhr.send(formData);
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  };

  return (

    <section id="video-post" className="section">
        <div className="add-post-header">
          <h4>{isEditing ? "Edit Your Video Post" : "Post Your Video Content"}</h4>
          {!isEditing && <small>Your post will require moderator approval.</small>}
        </div>
        <div className="container">
          {loading && (
            <div id="overlay">
              <div className="loading-text">Post uploading...</div>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
              </div>
              <div className="progress-text">
                {uploadProgress < 100 ? `${Math.round(uploadProgress)}%` : "Upload Complete"}
              </div>
            </div>
          )}
    
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                required
              />
            </div>
    
            {!isEditing && (
              <div className="form-group">
                <label htmlFor="slug">Slug</label>
                <input
                  type="text"
                  value={slug}
                  readOnly
                  className={isSlugUnique ? "valid" : "invalid"}
                />
                {isCheckingSlug && <small>Checking slug...</small>}
                {slugError && <small className="error">{slugError}</small>}
              </div>
            )}
    
            {isSlugUnique && (
              <>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="image">Thumbnail</label>
                    <input
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    {imagePreview && (
                      <div className="image-preview">
                        <img src={imagePreview} alt="Preview" />
                      </div>
                    )}
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="video">Video</label>
                    <input
                      name="video"
                      type="file"
                      accept="video/*"
                      onChange={handleVideoChange}
                    />
                    {videoPreview && (
                      <video controls>
                        <source src={videoPreview} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
    
                <div className="form-group">
                  <label htmlFor="topic">Topic</label>
                  <select value={topic} onChange={(e) => setTopic(e.target.value)} required>
                    <option value="">Select a topic</option>
                    <option value="desi-new-videos">Desi New Videos HD / SD</option>
                    <option value="desi-old-videos">Desi Old Videos HD / SD</option>
                    <option value="desi-new-pics">Desi New Pics HD / SD</option>
                    <option value="desi-old-pics">Desi Old Pictures HD / SD</option>
                    <option value="desi-model-webcam-girls">Desi Model | Webcam Girls</option>
                    <option value="live-streams-instragram-onlyfans">Live Streams | Instagram | OnlyFans</option>
                    <option value="world-pronster-videos">World Pronster Videos</option>
                  </select>
                </div>
    
                <div className="form-group">
                  <label htmlFor="tags">Tags</label>
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a tag and press Enter"
                  />
                  <div className="tag-container">
                    {tags.map((tag, index) => (
                      <div key={index} className="tag">
                        <span>{tag}</span>
                        <button type="button" onClick={() => removeTag(index)}>x</button>
                      </div>
                    ))}
                  </div>
                </div>
    
                <div className="form-group">
                  <button type="submit" id="PostButton" disabled={!isSlugUnique}>
                    {isEditing ? "Update Post" : "Create Post"}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    );
}

export default VideoPosts;
